import React from "react";
import img from "../../assets/HomePage/WhoWeAreimg.svg";

const WhoWeAre = () => {
  return (
    <div className="w-full px-10 lg:px-20 py-10 md:py-16 flex flex-col md:flex-row">
      <div className="w-full text-justify items-center prose prose-lg">
        <h2 className="text-base md:text-2xl lg:text-4xl font-semibold text-gray-800 text-center md:text-left">
          Who We Are?
        </h2>
        <div className="flex flex-col md:flex-row items-center md:items-stretch space-x-0 md:space-x-10 lg:space-x-20 xl:space-x-48 mt-4 lg:mt-10">
          {/* Left Section - Text */}
          <div className="text-gray-500 space-y-4 md:space-y-2 lg:space-y-6 text-xs md:text-sm lg:text-base w-full md:w-[40vw]">
            <p>
              Pando India Software Consultant is one of the world’s leading software consulting
              companies. It was established on 28 December 2017 to provide clients with a better
              understanding of people and inspire growth.
            </p>
            <p>
              Pando’s Service changes approach helps ‘contract the center’ through the utilization
              of digital technologies across heritage conditions inside an enterprise, empowering
              organizations to remain ahead in an impacting world.
            </p>
            <p>
              We associate organizations and consumers and continue to convey bits of knowledge ‘on
              request’ to organizations of all sizes and furnish customers with the capacity to
              shape the product they use.
            </p>
          </div>

          {/* Right Section - Image */}
          <div className="w-full md:w-1/2 lg:w-2/5 flex justify-center items-stretch">
            <div className="w-full max-w-[300px] md:max-w-full max-h-[280px] lg:max-h-[360px] xl:max-h-[280px] overflow-hidden mt-8 md:py-0">
              <img src={img} alt="Who We Are" className="w-full h-full object-cover rounded-xl" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhoWeAre;
