import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/autoplay";
import img1 from "../../assets/HomePage/Heroimg1.svg";
import img2 from "../../assets/HomePage/Heroimg2.svg";
import img3 from "../../assets/HomePage/Heroimg3.svg";
import mobilebgimg from "../../assets/HomePage/Heromobileimg.svg";

const slides = [
  {
    image: img1,
    title1: "Innovative",
    title2: "Digital Solutions",
    subtitle:
      "Harnessing cutting-edge technology and strategic insights to drive innovation, excellence, and transformation in your digital journey!",
  },
  {
    image: img2,
    title1: "Unlocking",
    title2: "Market Potential",
    subtitle:
      "Smart research, data-driven insights, and industry expertise to drive growth and seize new opportunities with confidence!",
  },
  {
    image: img3,
    title1: "Data-Driven Business",
    title2: "Empowerment",
    subtitle:
      "Harnessing market intelligence and analytics to drive informed decisions and stay ahead in a competitive landscape!",
  },
];

const LandingPage = () => {
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    window.history.scrollRestoration = "manual";
    window.scrollTo(0, 0);
    return () => {
      window.history.scrollRestoration = "auto";
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, 7000);

    return () => clearInterval(interval);
  }, []);

  const handleClick = () => {
    navigate("/#solutions");
    setTimeout(() => {
      const element = document.getElementById("solutions");
      if (element) {
        window.scrollTo({ top: element.offsetTop - 80, behavior: "smooth" });
      }
    }, 300);
  };

  return (
    <div className="relative flex h-[48vh] md:h[90vh] [@media(min-height:600px)_and_(max-height:600px)]:h-[100vh] xl:h-[90vh] main-height">
      <Swiper
        modules={[Autoplay]}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        centeredSlides={true}
        loop={true}
        speed={10}
        slidesPerView={1}
        className="w-full h-full"
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            {/* Left Section */}
            <div className="absolute w-full px-10 md:px-0 md:w-8/12 xl:w-7/12 md:pl-10 lg:pl-16 xl:pl-20 py-24 md:py-28 lg:py-36 text-center md:text-left">
              <h1 className="text-xl md:text-2xl lg:text-4xl xl:text-5xl font-medium text-gray-800 tracking-wider leading-snug">
                {slide.title1}
              </h1>
              <h1 className="text-xl md:text-2xl lg:text-4xl xl:text-5xl font-medium text-gray-800 tracking-wider leading-snug mt-1 lg:mt-3">
                {slide.title2}
              </h1>
              <p className="text-xs md:text-sm xl:text-base text-gray-600 w-full md:w-[40vw] xl:w-[32vw] mt-8">
                {slide.subtitle}
              </p>
              <button
                onClick={handleClick}
                className="relative px-6 mt-8  lg:px-8 py-2 lg:py-3 text-sm md:text-base bg-gradient-to-r from-red-400 to-orange-400 text-white rounded-md shadow-md hover:opacity-90 cursor-pointer z-10"
              >
                Read More
              </button>
            </div>

            {/* Right Section (Image & Badges) */}
            <div className="relative w-full h-auto md:flex hidden justify-end overflow-hidden xl:-mt-10 xl:-mr-20 custom-min-height">
              <img
                src={slide.image}
                alt="Illustration"
                className="md:w-full xl:-mr-10  md:h-[180%] xl:h-[200vh] -z-10"
              />
            </div>
            <div className="relative md:hidden flex">
              <img
                src={mobilebgimg}
                alt="Mobile Background"
                className="w-screen h-full object-cover -z-10"
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default LandingPage;
