import React, { useState, useCallback, useRef } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { Solution } from "../Solution/Solution";
import { useOutsideClick } from "./useOutsideClick";
import { Services } from "../../Services/Services";
import { Products } from "../products/Products";
export const NavSmallScreen = ({ toggle }: { toggle: () => void }) => {
  const SolutionMenuRef = useRef<HTMLDivElement>(null);
  const ServiceMenuRef = useRef<HTMLDivElement>(null);
  const ProductMenuRef = useRef<HTMLDivElement>(null);
  const [isSolution, setIsSolution] = useState(false);
  const [isService, setIsService] = useState(false);
  const [isProduct, setIsProduct] = useState(false);
  const showSolutions = useCallback(() => {
    setIsSolution(function (prevState) {
      return !prevState;
    });
  }, []);

  const showServices = useCallback(() => {
    setIsService(function (prevState) {
      return !prevState;
    });
  }, []);
  const showProduct = useCallback(() => {
    setIsProduct((prevState) => !prevState);
  }, []);

  useOutsideClick(ProductMenuRef.current!, () => {
    setIsProduct(false);
  });

  useOutsideClick(SolutionMenuRef.current!, () => {
    setIsSolution(false);
  });

  useOutsideClick(ServiceMenuRef.current!, () => {
    setIsService(false);
  });
  useOutsideClick(ProductMenuRef.current!, () => {
    setIsProduct(false);
  });
  return (
    <div
      className={
        "block absolute w-full top-0 left-0 z-10 flex-col justify-evenly items-center bg-white overflow-x-auto"
      }
    >
      <button
        className="absolute top-0 right-0 mt-8 mr-8"
        onClick={() => {
          toggle();
        }}
      >
        <svg
          className="w-8 h-8 text-gray-600"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <line x1="18" y1="6" x2="6" y2="18" />
          <line x1="6" y1="6" x2="18" y2="18" />
        </svg>
      </button>
      <ul className=" nav-links flex flex-col mt-20 text-center justify-center min-h-[400px]">
        <li className=" nav-text">
          <Link
            to={"/"}
            className="links"
            onClick={() => {
              toggle();
              window.location.href = "/";
            }}
          >
            <span className="font-semibold">Home</span>
          </Link>
        </li>

        <li className="nav-text">
          <div className="links" ref={SolutionMenuRef}>
            {" "}
            {/* <span onClick={showSolutions} className="font-semibold">
              Solutions
            </span> */}
            <HashLink
              smooth
              to="/#solutions"
              scroll={(el) => {
                window.scrollTo({ top: el.offsetTop - 80, behavior: "smooth" });
                toggle();
              }}
              className="font-semibold"
            >
              Solutions
            </HashLink>
          </div>
          {isSolution ? <Solution toggle={toggle} /> : ""}
        </li>

        {/* <li className=" nav-text">
          <div className="links" ref={ServiceMenuRef}>
            {" "}
            <span onClick={showServices} className="font-semibold">
              Services
            </span>
          </div>
          {isService ? <Services toggle={toggle} /> : ""}
        </li> */}

        <li className="nav-text">
          <div className="links" ref={ProductMenuRef}>
            {/* <span onClick={showProduct} className="font-semibold">
              Product
            </span> */}

            <HashLink
              smooth
              to="/#products"
              scroll={(el) => {
                window.scrollTo({ top: el.offsetTop - 80, behavior: "smooth" });
                toggle();
              }}
              className="font-semibold"
            >
              Product
            </HashLink>
          </div>
          {isProduct && <Products toggle={toggle} />} {/* Add the Product component */}
        </li>
        <li className="nav-text">
          <Link
            to={"/about"}
            className="links"
            onClick={() => {
              toggle();
            }}
          >
            <span className="font-semibold">About Us</span>
          </Link>
        </li>

        {/* <li className=" nav-text">
          <Link
            to={"/blog"}
            className="links"
            onClick={() => {
              toggle();
            }}
          >
            <span className="font-semibold">Blog</span>
          </Link>
        </li> */}

        <li className=" nav-text">
          <Link
            to={"/Career"}
            className="links"
            onClick={() => {
              toggle();
            }}
          >
            <span className="font-semibold">Career</span>
          </Link>
        </li>
        <li className=" nav-text">
          <Link
            to={"/contact"}
            className="links"
            onClick={() => {
              toggle();
            }}
          >
            <span className="font-semibold">Contact Us</span>
          </Link>
        </li>

        {/* <li className=" nav-text">
          <Link
            to={"/subscribe"}
            className="links"
            onClick={() => {
              toggle();
            }}
          >
            <span className="font-semibold">Subscribe Us</span>
          </Link>
        </li> */}
      </ul>
    </div>
  );
};
