import React from "react";
import ContactUsHero from "../../components/ContactUsPage/ContactUsHero";
import ContactUsForm from "../../components/ContactUsPage/ContactForm";
import ContactLinks from "../../components/ContactUsPage/ContactLinks";

export const ContactUs = () => {
  return (
    <div>
      <ContactUsHero />
      <ContactUsForm />
      <ContactLinks />
    </div>
  );
};
