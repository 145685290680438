import React from "react";

const JoinUs = () => {
  return (
    <div className="w-full py-36 px-10 lg:px-20">
      <div className="max-w-3xl mx-auto">
        {/* Right - Text Section */}
        <div className="text-center">
          <h2 className="text-xl md:text-3xl lg:text-4xl font-semibold text-[#253E52]">
            Why Work with Us?
          </h2>
          <p className="text-gray-600 text-xs md:text-sm lg:text-base leading-relaxed text-justify mt-12">
            We as an organization provide associates with the chance to achieve revolutionary
            results that benefit society as a whole and demonstrate that anything is possible via
            the application of innovation and our contextual expertise.
          </p>
          <p className="text-gray-600 text-xs md:text-sm lg:text-base leading-relaxed text-justify mt-8">
            By giving them the chance to access and benefit from the immense collective experience
            that exists within Pando India Software Consultant, we empower our associates to create
            unique solutions. We make sure they continue to be at the forefront of innovation.
          </p>
          <p className="text-gray-600 text-xs md:text-sm lg:text-base leading-relaxed text-justify mt-8">
            Regardless of their age or career stage, our people are encouraged in discovering and
            becoming the professionals they were meant to be through upskilling and reskilling as
            well as opportunities to move throughout the business.
          </p>
        </div>
      </div>
    </div>
  );
};

export default JoinUs;
