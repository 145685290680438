import React from "react";
import { HashLink } from "react-router-hash-link";
import img1 from "../../assets/FooterPage/facebook.svg";
import img2 from "../../assets/FooterPage/gmail.svg";
import img3 from "../../assets/FooterPage/linkedin.svg";
import img4 from "../../assets/FooterPage/contact.svg";
import { useLocation, useNavigate } from "react-router-dom";

export function Footer() {
  const location = useLocation();
  const navigate = useNavigate();

  const handleSolutionsClick = (e) => {
    e.preventDefault(); 

    if (location.pathname === "/") {
      
      const element = document.getElementById("solutions");
      if (element) {
        window.scrollTo({ top: element.offsetTop - 80, behavior: "smooth" });
      }
    } else {
      
      navigate("/#solutions");
      setTimeout(() => {
        const element = document.getElementById("solutions");
        if (element) {
          window.scrollTo({ top: element.offsetTop - 80, behavior: "smooth" });
        }
      }, 500);
    }
  };


  return (
    <div className="w-full">
      <div className="bg-gray-800 text-white py-16">
        <div className="px-10 lg:px-20 grid grid-cols-1 md:grid-cols-3 gap-10 justify-center">
          {/* Address Section */}
          <div className="text-center md:text-left">
            <div className="flex justify-center md:justify-start mb-6">
              <iframe
                title="Google Map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.78201908367!2d77.36752357610891!3d28.606315485269707!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce59ed188b333%3A0x13827186206f997e!2sPando%20india%20software%20Consultants!5e0!3m2!1sen!2sin!4v1687154767322!5m2!1sen!2sin"
                className="w-[250px] lg:w-[250px] lg:h-[180px] xl:w-[320px] xl:h-[200px] md:w-[220px]"
                style={{ borderRadius: "15px", border: "8px solid white" }}
                loading="lazy"
              ></iframe>
            </div>
            <div>
              <p className="text-white text-base md:text-lg lg:text-xl mb-4">Address:</p>
              <p className="text-gray-300 text-xs md:text-base">
                A-1, Second Floor, Sector 59, Noida UP
              </p>
            </div>
          </div>

          {/* Solutions Section */}
          <div className="lg:mx-auto text-center md:text-left">
            <h6 className="text-base md:text-lg lg:text-xl mb-4">Solutions</h6>
            <ul className="space-y-2 text-xs md:text-base">
              {[
                { name: "Market Research", link: "#" },
                { name: "Block Chain Technology", link: "#" },
                { name: "Software Development", link: "#" },
                { name: "Online Trading", link: "#" },
                { name: "CRM/Dashboard", link: "#" },
                { name: "DevOps", link: "#" },
                { name: "Machine Learning", link: "#" },
                { name: "Cloud Service", link: "#" },
              ].map((item, index) => (
                <li key={index}>
                  <HashLink
                    smooth
                    to="#solutions"
                    onClick={handleSolutionsClick}
                    className="text-gray-300 hover:text-white hover:underline"
                  >
                    {item.name}
                  </HashLink>
                </li>
              ))}
            </ul>
          </div>

          {/* Contact Section */}
          <div>
            <h6 className="text-base md:text-lg lg:text-xl mb-4 text-center md:text-left">
              Contact
            </h6>
            <p className="text-gray-300 mb-2 text-center md:text-left text-xs md:text-base">
              info@pandoconsultants.com
            </p>
            <p className="text-gray-300 mb-16 md:mb-24 lg:mb-32 text-center md:text-left text-xs md:text-base">
              +91-8178745624
            </p>
            {/* Follow Us Section */}
            <div className="lg:w-96 md:w-48 md:flex flex-col">
              <h6 className="text-base md:text-lg mb-4 lg:text-xl text-center md:text-start">
                Follow Us
              </h6>
              <div className="grid grid-cols-2 lg:grid-cols-4 gap-6 justify-items-center md:justify-items-start">
                <a
                  href="https://www.facebook.com/people/Pando-India-Software-Consultants/100089263113195/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={img1}
                    alt="Facebook"
                    className="w-4 md:w-6 lg:w-8 hover:scale-125 transition-transform duration-300"
                  />
                </a>
                <a href="mailto:info@pandoconsultants.com">
                  <img
                    src={img2}
                    alt="Gmail"
                    className="w-4 md:w-6 lg:w-8 hover:scale-125 transition-transform duration-300"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/pando-india-software-consultants/mycompany/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={img3}
                    alt="LinkedIn"
                    className="w-4 md:w-6 lg:w-8 hover:scale-125 transition-transform duration-300"
                  />
                </a>
                <a href="tel:+918178745624">
                  <img
                    src={img4}
                    alt="Contact"
                    className="w-4 md:w-6 hover:scale-125 transition-transform duration-300 flex lg:hidden"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
