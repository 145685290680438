import React, { useState, useEffect } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import img1 from "../../assets/HomePage/ServiceCard1.svg";
import img2 from "../../assets/HomePage/ServiceCard2.svg";
import img3 from "../../assets/HomePage/ServiceCard3.svg";
import img4 from "../../assets/HomePage/ServiceCard4.svg";
import img5 from "../../assets/HomePage/ServiceCard5.svg";
import img6 from "../../assets/HomePage/ServiceCard6.svg";
import img7 from "../../assets/HomePage/ServiceCard7.svg";
import img8 from "../../assets/HomePage/ServiceCard8.svg";

const solutions = [
  {
    id: 1,
    title: "Software Development",
    description: "Creating digital solutions that drive innovation and efficiency.",
    icon: img1,
  },
  {
    id: 2,
    title: "DevOps",
    description: "Bridging the gap between development and operations for seamless collaboration.",
    icon: img2,
  },
  {
    id: 3,
    title: "Blockchain Technology",
    description: "Securely revolutionizing industries through decentralized technology.",
    icon: img3,
  },
  {
    id: 4,
    title: "Online Trading",
    description: "Online trading opens doors to global investment opportunities.",
    icon: img4,
  },
  {
    id: 5,
    title: "Market Research",
    description: "Market research uncovers consumer insights, driving business success.",
    icon: img5,
  },
  {
    id: 6,
    title: "CRM/Dashboard",
    description: "Unlocking customer-centric intelligence for business growth.",
    icon: img6,
  },
  {
    id: 7,
    title: "Machine learning",
    description: "It revolutionizes industries with intelligent data-driven solutions.",
    icon: img7,
  },
  {
    id: 8,
    title: "Cloud Service",
    description: "Empowering businesses with flexible, scalable, and innovative solutions.",
    icon: img8,
  },
];

const Services = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [activeButton, setActiveButton] = useState("next");
  const [cardsToShow, setCardsToShow] = useState(2);

  useEffect(() => {
    const handleResize = () => {
      setCardsToShow(window.innerWidth < 1024 ? 1 : 2);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Function to move to the next slide
  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < solutions.length - cardsToShow ? prevIndex + 1 : 0
    );
    setActiveButton("next");
  };

  // Function to move to the previous slide
  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : solutions.length - cardsToShow
    );
    setActiveButton("prev");
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 3000);

    return () => clearInterval(interval); 
  }, [currentIndex]); 

  return (
    <div id="solutions" className="w-full px-10 lg:px-16 py-16">
      <div className="flex flex-col md:flex-row items-center md:items-start md:gap-10">
        {/* Title & Text Section */}
        <div className="w-full text-center md:text-left md:mt-8">
          <h2 className="text-base md:text-3xl lg:text-4xl font-semibold text-gray-800">
            OUR SOLUTIONS
          </h2>
          <p className="text-gray-500 text-xs md:text-base lg:text-lg mt-4 md:mt-8 w-11/12">
            Pando delivers market research for data-driven business decisions.
          </p>

          {/* Arrow Buttons for medium and larger screens */}
          <div className="hidden md:flex justify-center md:justify-start items-center mt-8 md:mt-16 gap-10">
            <button
              onClick={() => {
                prevSlide();
              }}
              className={`p-1 md:p-3 rounded-full shadow-md border ${
                activeButton === "prev"
                  ? "bg-black text-white"
                  : "bg-white hover:bg-gray-100 text-black"
              }`}
            >
              <ChevronLeft />
            </button>
            <button
              onClick={() => {
                nextSlide();
              }}
              className={`p-1 md:p-3 rounded-full shadow-md border ${
                activeButton === "next"
                  ? "bg-black text-white"
                  : "bg-white hover:bg-gray-100 text-black"
              }`}
            >
              <ChevronRight />
            </button>
          </div>
        </div>

        <div className="w-full relative flex flex-col md:flex-row gap-6 md:gap-20 xl:gap-24 mt-8 md:mt-0 xl:pr-10">
          {/* Left Arrow */}
          <button
            onClick={prevSlide}
            className="absolute left-[-20px] top-1/2 transform -translate-y-1/2 md:hidden p-1 rounded-full shadow-md border bg-none hover:bg-gray-100 text-black"
          >
            <ChevronLeft />
          </button>

          {/* Service Cards */}
          <div className="w-full flex flex-col md:flex-row gap-6 md:gap-20 xl:gap-24 mt-8 md:mt-0 xl:pr-10">
            {solutions.slice(currentIndex, currentIndex + cardsToShow).map((solution) => (
              <div
                key={solution.id}
                id={`solution-${solution.id}`}
                className={`w-full lg:w-[25vw] xl:w-[23vw] h-full lg:h-[370px] xl:h-[340px] p-6 md:p-10 rounded-3xl shadow-lg ${
                  solution.id % 2 !== 0 ? "bg-[#91E3F833]" : "bg-[#E7782D33]"
                }`}
              >
                {/* Service Icon */}
                <div className="flex justify-center md:justify-start mb-10 md:mb-14">
                  <img
                    src={solution.icon}
                    alt={solution.title}
                    className="w-16 md:w-24 lg:w-28 h-16 md:h-24 lg:h-28"
                  />
                </div>

                {/* Service Content */}
                <h3 className="text-base md:text-lg font-semibold text-gray-800 text-center md:text-left">
                  {solution.title}
                </h3>
                <p className="text-xs md:text-sm text-gray-600 text-center md:text-left mt-2 mb-4">
                  {solution.description}
                </p>
              </div>
            ))}
          </div>
          <button
            onClick={nextSlide}
            className="absolute right-[-20px] top-1/2 transform -translate-y-1/2 md:hidden p-1 rounded-full shadow-md border bg-none hover:bg-gray-100 text-black"
          >
            <ChevronRight />
          </button>
        </div>
      </div>

      {/* Arrow Buttons for small screens (placed below the service cards) */}
      <div className="md:hidden flex justify-center items-center mt-8 gap-10">
        <button
          onClick={() => {
            prevSlide();
          }}
          className={`p-1 rounded-full shadow-md border ${
            activeButton === "prev"
              ? "bg-black text-white"
              : "bg-white hover:bg-gray-100 text-black"
          }`}
        >
          <ChevronLeft />
        </button>
        <button
          onClick={() => {
            nextSlide();
          }}
          className={`p-1 rounded-full shadow-md border ${
            activeButton === "next"
              ? "bg-black text-white"
              : "bg-white hover:bg-gray-100 text-black"
          }`}
        >
          <ChevronRight />
        </button>
      </div>
    </div>
  );
};

export default Services;
