/* eslint-disable react/no-unescaped-entities */
import React from "react";

const OurMission = () => {
  return (
    <div className="w-full py-20 lg:py-32 px-10 lg:px-20 flex items-center justify-center bg-[#F8F8FF]">
      <div className="max-w-3xl text-center">
        <h2 className="text-xl md:text-3xl lg:text-4xl font-medium text-[#253E52]">
          Our Mission
        </h2>
        <p className="text-gray-600 text-xs md:text-sm lg:text-base leading-relaxed mt-8 lg:mt-14">
          We are committed to delivering exceptional customer service, high-quality tools and
          solutions, expert guidance and support, and cutting-edge information technologies that
          drive success.
        </p>
      </div>
    </div>
  );
};

export default OurMission;
