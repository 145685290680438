import React from "react";
import { Route, Routes } from "react-router-dom";
import { BlogCard } from "./components/Blog/BlogCard";
// import { Home } from "./pages/Home/Home";
import { Layout } from "./pages/Layout";
import { NotFound } from "./pages/NotFound/NotFound";
// import { CareerHome } from "./components/Career/CareerHome";
import { GetDemo } from "./components/GetDemo";
// import { ContactUs } from "./components/ContactUs";
// import { AboutUs } from "./components/AboutUs";
import { Cardsection } from "./components/Career/Cardsection";
import ReadMorePage from "./pages/ReadMore/ReadMore";
import ClientReadMore from "./pages/ClientReadMore/ClientReadMore";
import MarketResearchReadMore from "./pages/MarketResearchReadMore/MarketResearchReadMore";
import Blockchain from "./pages/Blockchain/Blockchain";
import SoftwareDevelopmentRead from "./pages/SoftwareDevelopmentRead/SoftwareDevelopmentRead";
import { Applyhome } from "./components/Applynow/Applyhome ";
import DigitallyEmpoweringPage from "./pages/DigitallyEmpoweringPage/DigitallyEmpoweringPage";
import CRMReadMore from "./pages/CRMReadMore/CRMReadMore";
import DigitalReadMore from "./pages/DigitalReadMore/DigitalReadMore";
import EmployeeEngagementReadMore from "./pages/EmployeeEngagementReadMore/EmployeeEngagementReadMore";
import DevOpsReadMore from "./pages/DevOpsReadMore/DevOpsReadMore";
import MachineLearningReadMore from "./pages/MachineLearningReadMore/MachineLearningReadMore";
import CloudServices from "./pages/CloudServices/CloudServices";
import { Login } from "./components/Login/login";
import { Model } from "./components/Model/Model";
import ForexReadMore from "./pages/ForexReadMore/ForexReadMore";
import { Banking } from "./pages/BankingReadMore/Banking";
import { Fmcg } from "./pages/FMCGReadMore/Fmcg";
import { SoftwareTools } from "./pages/SoftwareToolsReadMore/SoftwareTools";
import { Industries } from "./pages/IndustriesIncludeReadMore/Industries";
import LatestTechnology from "./pages/LatestTechnologyReadMore/LatestTechnology";
import AnniversaryReadMore from "./pages/AnniversaryReadMore/AnniversaryReadMore";
import FullStackApplyNow from "./ApplyNow/FullStackApplyNow/FullStackApplyNow";
import ProjectManagerApplyNow from "./ApplyNow/ProjectManagerApplyNow/ProjectManagerApplyNow";
import SurveyProgrammerApplyNow from "./ApplyNow/SurveyProgrammerApplyNow/SurveyProgrammerApplyNow";
import Python from "./ApplyNow/PythonApplyNow/PythonApplyNow";
import DataAnalystApplyNow from "./ApplyNow/DataAnalystApplyNow/DataAnalystApplyNow";
import SalesAdministratorApplyNow from "./ApplyNow/SalesAdministratorApplyNow/SalesAdministratorApplyNow";
import ClientServiceApplyNow from "./ApplyNow/ClientServiceManagerApplyNow/ClientServiceApplyNow";
import QualityAssuranceApplyNow from "./ApplyNow/QualityAssuranceApplyNow/QualityAssuranceApplyNow";
import InternetThings from "./Services/Pages/InternetThings";
import BigData from "./Services/Pages/BigData";
import MachineLearning from "./Services/Pages/MachineLearning";
import WebDesign from "./Services/Pages/WebDesign";
import Enterprise from "./Services/Pages/Enterprise";
import UiUx from "./Services/Pages/UiUx";
import Financial from "./Services/Pages/Financial";
import Ios from "./Services/Pages/Ios";
import Android from "./Services/Pages/Android";
import Customer from "./Services/Pages/Customer";
import { Home } from "./pages/HomePage/HomePage";
import { ContactUs } from "./pages/ContactUsPage/ContactUs";
import { AboutUs } from "./pages/AboutUsPage/AboutUs";
import { CareerHome } from "./pages/JobsPage/Career";
// import { Subscribe } from "./components/Subscribe/Subscribe";

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="/blog" index element={<BlogCard />} />
        <Route path="/career" index element={<CareerHome />} />
        <Route path="/demo" index element={<GetDemo />} />
        <Route path="/contact" index element={<ContactUs />} />
        <Route path="/about" index element={<AboutUs />} />
        <Route path="/career-card" element={<Cardsection />} />
        <Route path="/online-trading" index element={<ReadMorePage />} />
        <Route path="/client" index element={<ClientReadMore />} />
        <Route path="/market-research" index element={<MarketResearchReadMore />} />
        <Route path="/block-chain" index element={<Blockchain />} />
        <Route path="/software-development" index element={<SoftwareDevelopmentRead />} />
        <Route path="/apply-now-blog" index element={<Applyhome />} />
        <Route path="/digitally-empowering" index element={<DigitallyEmpoweringPage />} />
        <Route path="/crm" index element={<CRMReadMore />} />
        <Route path="/digital" index element={<DigitalReadMore />} />
        <Route path="/employee" index element={<EmployeeEngagementReadMore />} />
        <Route path="/develop" index element={<DevOpsReadMore />} />
        <Route path="/machine" index element={<MachineLearningReadMore />} />
        <Route path="/cloud" index element={<CloudServices />} />
        <Route path="/login" index element={<Login />} />
        <Route path="/scroll" index element={<Model />} />
        <Route path="/forex" index element={<ForexReadMore />} />
        <Route path="/banking" index element={<Banking />} />
        <Route path="/fmcg" index element={<Fmcg />} />
        <Route path="/software-tools" index element={<SoftwareTools />} />
        <Route path="/industries" index element={<Industries />} />
        <Route path="/latest-technology" index element={<LatestTechnology />} />
        <Route path="/anniversary" index element={<AnniversaryReadMore />} />
        <Route path="/full-stack" index element={<FullStackApplyNow />} />
        <Route path="/project-manager" index element={<ProjectManagerApplyNow />} />
        <Route path="/survey-programmer" index element={<SurveyProgrammerApplyNow />} />
        <Route path="/python" index element={<Python />} />
        <Route path="/data-analyst" index element={<DataAnalystApplyNow />} />
        <Route path="/sales-administrator" index element={<SalesAdministratorApplyNow />} />
        <Route path="/client-service" index element={<ClientServiceApplyNow />} />
        <Route path="/quality-assurance" index element={<QualityAssuranceApplyNow />} />
        <Route path="/internet-things" index element={<InternetThings />} />
        <Route path="/big-data" index element={<BigData />} />
        <Route path="/machine-learning" index element={<MachineLearning />} />
        <Route path="/web-design" index element={<WebDesign />} />
        <Route path="/enterprise-dev" index element={<Enterprise />} />
        <Route path="/ui-ux" index element={<UiUx />} />
        <Route path="/financial" index element={<Financial />} />
        <Route path="/ios-development" index element={<Ios />} />
        <Route path="/android" index element={<Android />} />
        <Route path="/customer" index element={<Customer />} />
        {/* <Route path="/subscribe" index element={<Subscribe />} /> */}
      </Route>
      <Route path="*" element={<NotFound />}></Route>
    </Routes>
  );
};
