import React from "react";
import img1 from "../../assets/AboutUsPage/Aboutimg2.svg";

export default function OurVision() {
  return (
    <div className="relative w-full h-[300px] md:h-[400px] flex items-center justify-center text-white">
      {/* Background Image */}
      <div className="absolute inset-0">
        <img src={img1} alt="About Us" className="w-full h-full object-cover" />
        {/* Dark Overlay */}
        <div className="absolute inset-0 bg-black opacity-60"></div>
      </div>

      {/* Centered Content */}
      <div className="relative z-10 text-center px-4">
        <h2 className="text-xl md:text-3xl lg:text-4xl font-medium ">Our Vision</h2>
        <p className="mt-6 lg:mt-10 text-xs md:text-sm lg:text-base max-w-2xl">
          Our vision is to enhance the lives of our customers by providing high-quality solutions at
          competitive prices while prioritizing their needs in everything we do.
        </p>
      </div>
    </div>
  );
}
