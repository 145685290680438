import React from "react";
import "./index.css";
import HeroSession  from "../../components/HomePage/Hero";
import Services from "../../components/HomePage/Services";
import Products from "../../components/HomePage/Products";
import OurTeam from "../../components/HomePage/OurTeam";
import WhoWeAre from "../../components/HomePage/WhoWeAre";

export const Home = () => {
  return (
    <div>
      <HeroSession />
      <Services />
      <Products />
      <OurTeam />
      <WhoWeAre />
    </div>
  );
};
