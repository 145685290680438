/* eslint-disable react/no-unescaped-entities */
import React from "react";
import bipinsinha from "../../assets/HomePage/bipinsinha.svg";
import manjeetShahi from "../../assets/manjeet.png";

const OurTeam = () => {
  return (
    <div className="w-full flex flex-col mx-auto py-10 px-10 md:py-16 lg:px-20">
      {/* Heading Section */}
      <div className="w-full max-w-lg text-center md:text-left">
        <h2 className="text-base md:text-3xl lg:text-5xl font-medium text-gray-800">Our Team</h2>
        <p className="text-gray-500 mt-4 text-xs md:text-base">
          Driving innovation in Product and Services with cutting-edge technologies
        </p>
      </div>

      {/* Team Members */}
      <div className="flex flex-col gap-10 md:gap-14 mt-10 md:mt-14 w-full">
        {/* Team Member 1 */}
        <div className="flex flex-col md:flex-row items-center gap-6 lg:gap-16">
          <div className="w-[25vw] md:w-[18vw] h-auto flex">
            <img
              src={bipinsinha}
              alt="Bipin Sinha"
              className="h-[25vw] w-[25vw] md:w-[18vw] md:h-[18vw] object-cover rounded-full shadow-xl transition-transform transform perspective-1000 hover:rotate-y-6 hover:scale-105"
            />
          </div>

          <div className="bg-[#F9E4D5] p-10 text-center md:text-left md:p-10 lg:p-14 md:rounded-3xl flex items-center w-screen md:w-[75%]">
            <div>
              <h3 className="text-base md:text-2xl lg:text-4xl font-semibold text-gray-800">
                Bipin Sinha
              </h3>
              <p className="text-sm text-gray-600 font-medium mt-4">Founder and Director</p>
              <p className="text-xs md:text-sm lg:text-base text-gray-600 mt-4 leading-relaxed">
                To aid staff in concentrating their efforts, he developed key performance
                indicators. Work with other company experts to coordinate projects involving
                community service, promote adherence to corporate laws and regulations and ensure
                that the company's code of ethics is being followed.
              </p>
            </div>
          </div>
        </div>

        {/* Team Member 2 - Circular Image */}
        <div className="flex flex-col md:flex-row items-center gap-6 lg:gap-16">
          <div className="w-[25vw] md:w-[18vw] h-auto flex">
            <img
              src={manjeetShahi}
              alt="Manjeet Kumar Shahi"
              className="h-[25vw] w-[25vw] md:w-[17vw] md:h-[17vw] object-cover rounded-full shadow-xl transition-transform transform perspective-1000 hover:rotate-y-6 hover:scale-105"
            />
          </div>

          <div className="bg-[#91E3F833] p-10 text-center md:text-left md:p-10 lg:p-14 md:rounded-3xl flex items-center w-screen md:w-[75%]">
            <div>
              <h3 className="text-base md:text-2xl lg:text-4xl font-semibold text-gray-800">
                Manjeet Kumar Shahi
              </h3>
              <p className="text-sm text-gray-600 font-medium mt-4">Financial Advisor</p>
              <p className="text-xs md:text-sm lg:text-base text-gray-600 mt-4 leading-relaxed">
                The way he handles the finance and project of the organization is unique. He helps
                clients in knowing personnel as well as professional and financial information of
                the client by making use of data to create projections and client goals.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurTeam;
