/* eslint-disable react/no-unescaped-entities */
import React, { useState } from "react";
import axios from "axios";
import img from "../../assets/ContactUsPage/Contactusimg5.png";

const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [suggestion_area, setSuggestion_area] = useState("");
  const [suggestion_query, setSuggestion_query] = useState("");

  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [areaError, setAreaError] = useState("");
  const [queryError, setQueryError] = useState("");

  const [isSuccess, setIsSuccess] = useState(false);

  const handleNameChange = (e) => {
    const value = e.target.value;
    setName(value);

    if (!value.trim()) {
      setNameError("Name is required.");
    } else if (!/^[A-Z][a-zA-Z\s]+$/.test(name)) {
      setNameError("First letter must be uppercase and only letters/spaces are allowed.");
    } else {
      setNameError("");
    }
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    if (!value.trim()) {
      setEmailError("Email is required.");
    } else if (!/^[a-zA-Z0-9][a-zA-Z0-9._+-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
      setEmailError("Invalid email format.");
    } else if (/\.\./.test(value)) {
      setEmailError("Email cannot contain consecutive dots.");
    } else {
      setEmailError("");
    }
  };

  const handleMobileChange = (e) => {
    const value = e.target.value;
    setMobile(value);

    if (!value.trim()) {
      setMobileError("Mobile number is required.");
    } else if (!/^\d{10}$/.test(value)) {
      setMobileError("Please enter a valid 10-digit mobile number.");
    } else {
      setMobileError("");
    }
  };

  const handleSuggestionAreaChange = (e) => {
    const value = e.target.value;
    setSuggestion_area(value);

    if (!value) {
      setAreaError("Please select an option.");
    } else {
      setAreaError("");
    }
  };

  const handleQueryChange = (e) => {
    const value = e.target.value;
    setSuggestion_query(value);

    if (!value.trim()) {
      setQueryError("Message is required.");
    } else if (!/^[a-zA-Z0-9.,\s]{1,1000}$/.test(value)) {
      setQueryError("Only letters, numbers, commas, and dots (max 1000 characters).");
    } else {
      setQueryError("");
    }
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      if (!name.trim()) setNameError("Name is required.");
      if (!email.trim()) setEmailError("Email is required.");
      if (!mobile.trim()) setMobileError("Mobile number is required.");
      if (!suggestion_area) setAreaError("Please select an option.");
      if (!suggestion_query.trim()) setQueryError("Message is required.");

      const { data } = await axios.post("http://localhost:5000/api/contactus", {
        name,
        email,
        mobile,
        suggestion_area,
        suggestion_query,
      });

      if (data.success) {
        setIsSuccess(true);
        setName("");
        setEmail("");
        setMobile("");
        setSuggestion_area("");
        setSuggestion_query("");
        return;
      }
    } catch (error) {
      alert("Something went wrong");
    }
  };

  return (
    <div className="w-full flex items-center justify-center px-10 py-20">
      <div className="w-full max-w-3xl flex flex-col lg:flex-row items-center justify-center gap-12 md:gap-28 md:p-10">
        <div className="w-full">
          <h2 className="text-3xl sm:text-4xl text-center font-medium text-[#253E52]">
            Get In Touch
          </h2>
          <p className="text-[#787878] text-xs text-center sm:text-md mt-4 lg:mt-6">
            Pando India Software Consultants is USA-based on Market research and Finance Software
            services with a brilliant team and technology.
          </p>
          <form
            className="mt-12 lg:mt-16 grid grid-cols-1 gap-6 sm:grid-cols-2 sm:gap-10"
            onSubmit={handleSubmit}
          >
            <div className="space-y-2 col-span-2 sm:col-span-1">
              <label className="text-[#253E52] font-medium text-sm sm:text-md">
                Name <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                value={name}
                onChange={handleNameChange}
                placeholder="Enter your name"
                className="w-full py-4 px-6 rounded-lg bg-[#E2F4FE59] focus:outline-blue-300"
              />
              {nameError && <p className="text-red-500 text-sm">{nameError}</p>}
            </div>

            <div className="space-y-2 col-span-2 sm:col-span-1">
              <label className="text-[#253E52] font-medium text-sm sm:text-md">
                Email Address <span className="text-red-500">*</span>
              </label>
              <input
                type="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="Enter your email"
                className="w-full py-4 px-6 rounded-lg bg-[#E2F4FE59] focus:outline-blue-300"
              />
              {emailError && <p className="text-red-500 text-sm">{emailError}</p>}
            </div>

            <div className="space-y-2 col-span-2 sm:col-span-1">
              <label className="text-[#253E52] font-medium text-sm sm:text-md">
                Mobile Number <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                value={mobile}
                onChange={handleMobileChange}
                placeholder="Enter your mobile number"
                className="w-full py-4 px-6 rounded-lg bg-[#E2F4FE59] focus:outline-blue-300"
              />
              {mobileError && <p className="text-red-500 text-sm">{mobileError}</p>}
            </div>

            <div className="space-y-2 col-span-2 sm:col-span-1">
              <label className="text-[#253E52] font-medium text-sm sm:text-md">
                Suggestions/Queries Areas <span className="text-red-500">*</span>
              </label>
              <select
                value={suggestion_area}
                onChange={handleSuggestionAreaChange}
                className={`w-full py-4 px-6 rounded-lg bg-[#E2F4FE59] focus:outline-blue-300 ${
                  areaError ? "border border-red-500" : ""
                }`}
              >
                <option value="">-- Select an Option --</option>
                <option value="Product">Product</option>
                <option value="Services">Services</option>
                <option value="Collaboration">Collaboration</option>
                <option value="Support">Support</option>
                <option value="Market Research">Market Research</option>
                <option value="Others">Others</option>
              </select>
              {areaError && <p className="text-red-500 text-sm">{areaError}</p>}
            </div>

            <div className="space-y-2 col-span-2">
              <label className="text-[#253E52] font-medium text-sm sm:text-md">
                Your Suggestions/Queries <span className="text-red-500">*</span>
              </label>
              <textarea
                value={suggestion_query}
                onChange={handleQueryChange}
                placeholder="Enter your message"
                className="w-full h-48  py-4 px-6 rounded-lg bg-[#E2F4FE59] focus:outline-blue-300"
              ></textarea>
              {queryError && <p className="text-red-500 text-sm">{queryError}</p>}
            </div>

            <div className="col-span-2 flex justify-center">
              <button
                type="submit"
                className="col-span-2 sm:col-span-1 w-full sm:w-auto px-6 py-3 bg-gradient-to-r from-red-400 to-orange-400 text-white rounded-md shadow-md hover:opacity-90 transition"
              >
                Send Massage
              </button>
            </div>
          </form>

          {/* Success Popup */}
          {isSuccess && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white p-8 rounded-xl shadow-xl text-center max-w-md space-y-4">
                <img src={img} alt="Success" className="w-32 md:w-48 lg:64 mx-auto" />
                <h3 className="text-base md:text-lg lg:text-2xl font-semibold text-green-600 mt-4">
                  Message Sent Successfully!
                </h3>
                <p className="text-gray-600 mt-2 text-xs md:text-base">
                  Thank you for reaching out! Your message has been sent successfully. Our team will
                  get back to you soon.
                </p>
                <button
                  onClick={() => setIsSuccess(false)}
                  className="mt-4 text-base md:text-lg lg:text-xl bg-gradient-to-r from-red-400 to-orange-400 text-white rounded-md"
                >
                  Ok
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
