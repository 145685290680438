import React from "react";
import AboutHero from "../../components/AboutUsPage/AboutUsHero";
import WhoWeAre from "../../components/AboutUsPage/AboutUs";
import OurMission from "../../components/AboutUsPage/OurMission";
import OurVision from "../../components/AboutUsPage/OurVision";

export const AboutUs = () => {
  return (
    <div>
      <AboutHero />
      <WhoWeAre />
      <OurMission />
      <OurVision />
    </div>
  );
};
