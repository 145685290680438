import React from "react";
import CareerHero from "../../components/JobsPage/JoinHeroSection";
import JoinUs from "../../components/JobsPage/JoinUs";
import Jobs from "../../components/JobsPage/Jobs";

export const CareerHome = () => {
  return (
    <div>
      <CareerHero />
      <JoinUs />
      <Jobs />
    </div>
  );
};
