import React, { useState, useEffect } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import img1 from "../../assets/HomePage/ProductImage1.png";
import img2 from "../../assets/HomePage/ProductImage2.svg";
import img3 from "../../assets/HomePage/ProductImage3.svg";
import img4 from "../../assets/HomePage/ProductImage4.svg";
import img6 from "../../assets/HomePage/ProductImage5.svg";
import img7 from "../../assets/Group 76.svg";

const products = [
  {
    id: 1,
    title: "Student’s Corners",
    description:
      "Stay updated with government job notifications, insightful blogs, and mock tests to boost your career.",
    image: img2,
    bgColor: "#0167CD",
    visitWebLink: "https://studentscorners.com/",
    playStoreLink:
      "https://play.google.com/store/apps/details?id=com.pando.app.studentcornerkotlin&hl=en-IN",
  },
  {
    id: 2,
    title: "ICASH IQ",
    description:
      "Your Gateway to global surveys and rewards.",
    image: img3,
    bgColor: "#293394",
    visitWebLink: "https://icashiq.com",
    playStoreLink: null,
  },
  {
    id: 3,
    title: "XbinLegal",
    description:
      "Simplify tax compliance and company registration with XbinLegal your one-stop solution for seamless business setup.",
    image: img4,
    bgColor: "#FE725B",
    visitWebLink: "https://xbinlegal.pandoconsultants.com/",
    playStoreLink: null,
  },
  {
    id: 4,
    title: "Yours Article",
    description:
      "Explore diverse insights on tech, finance, and career with Yours Articles your go-to blog platform.",
    image: img6,
    bgColor: "#1B4B5A",
    visitWebLink: "https://yoursarticle.com/",
    playStoreLink: null,
  },
  {
    id: 5,
    title: "Easy Docoments",
    description:
      "Create, edit, and manage PDFs effortlessly with EasyDocoments your ultimate PDF solution.",
    image: img7,
    bgColor: "#059C9C",
    visitWebLink: "https://easydocoments.com/",
    playStoreLink:
      "https://play.google.com/store/apps/details?id=com.pando.easydocuments&pcampaignid=web_share",
  },
];

const Products = () => {
  const [displayedProducts, setDisplayedProducts] = useState(products.slice(0, 2));
  const [startIndex, setStartIndex] = useState(0);
  const [activeButton, setActiveButton] = useState("next");

  const nextSlide = () => {
    const newIndex = (startIndex + 1) % products.length;
    setStartIndex(newIndex);
    setDisplayedProducts([products[newIndex], products[(newIndex + 1) % products.length]]);
    setActiveButton("next");
  };

  const prevSlide = () => {
    const newIndex = (startIndex - 1 + products.length) % products.length;
    setStartIndex(newIndex);
    setDisplayedProducts([products[newIndex], products[(newIndex + 1) % products.length]]);
    setActiveButton("prev");
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 5000); 

    return () => clearInterval(interval); 
  }, [startIndex]);

  return (
    <div
      id="products"
      className="w-full h-auto bg-[#F8F8FF] flex flex-col items-center py-10 md:my-16 px-10 lg:px-16"
    >
      <div className="w-full flex flex-col md:flex-row items-center">
        {/* Left Section - Title & Text */}
        <div className="w-full lg:w-[32vw] text-center md:text-left">
          <div className="w-56 md:w-64 xl:w-72 mx-auto md:mx-0 lg:-ml-10 xl:ml-0">
            <img src={img1} alt="Product" />
          </div>
          <div className="space-y-6 px-10 md:px-0 md:pr-10 xl:px-10">
            <h2 className="text-base md:text-3xl lg:text-4xl font-medium text-gray-700">
              Explore Our Digital Products
            </h2>
            <p className="text-gray-500 text-xs md:text-base md:px-0">
              Discover our cutting-edge apps and websites designed to enhance your productivity and
              simplify your digital life.
            </p>
            {/* Arrow Buttons for medium and larger screens */}
            <div className="hidden md:flex justify-center md:justify-start items-center gap-10 py-4">
              <button
                onClick={prevSlide}
                className={`p-1 md:p-3 rounded-full shadow-md border ${
                  activeButton === "prev"
                    ? "bg-black text-white"
                    : "bg-white hover:bg-gray-100 text-black"
                }`}
              >
                <ChevronLeft />
              </button>
              <button
                onClick={nextSlide}
                className={`p-1 md:p-3 rounded-full shadow-md border ${
                  activeButton === "next"
                    ? "bg-black text-white"
                    : "bg-white hover:bg-gray-100 text-black"
                }`}
              >
                <ChevronRight />
              </button>
            </div>
          </div>
        </div>

        {/* Product Cards */}
        <div className="flex flex-col lg:flex-row md:space-x-28 lg:space-x-12 xl:space-x-28 items-center py-6 md:py-0 lg:ml-4 xl:ml-20">
          {displayedProducts.map((product, index) => {
            const isActive = index === 0;
            return (
              <div key={product.id} className="flex flex-col items-center">
                <div
                  className={`relative transition-transform duration-500 ease-out rounded-2xl shadow-lg h-full ${
                    isActive
                      ? "w-full md:w-[40vw] lg:w-[28vw] xl:w-[20vw] p-6 scale-100"
                      : "w-full hidden lg:block md:w-[18vw] lg:w-[24vw] xl:w-[18vw] p-6 scale-95"
                  } bg-white`}
                >
                  {/* Top Colored Bar */}
                  <div
                    className="absolute top-0 left-0 w-full rounded-t-2xl transition-all duration-500"
                    style={{
                      backgroundColor: product.bgColor,
                      height: isActive ? "18px" : "16px",
                    }}
                  />
                  {/* Image Section */}
                  <div className="flex justify-center">
                    <img
                      src={product.image}
                      alt={product.title}
                      className={`rounded-full transition-all mt-4 duration-500 ${
                        isActive ? "h-44 w-44" : "h-32"
                      }`}
                    />
                  </div>
                  {/* Title & Description */}
                  <div className="flex flex-col items-center text-center transition-all duration-500">
                    <h3
                      className={`font-semibold  ${
                        isActive ? "pt-6 text-base md:text-xl" : "pt-4 text-base"
                      }`}
                      style={{ color: product.bgColor }}
                    >
                      {product.title}
                    </h3>
                    <p
                      className={`${
                        isActive ? "pt-3 pb-6 text-xs md:text-sm" : "pt-3 pb-6 text-xs"
                      }`}
                    >
                      {product.description}
                    </p>
                  </div>
                </div>
                {/* Action Buttons - Show only for active card BELOW the card */}
                {isActive && (
                  <div className="flex flex-row items-center justify-center gap-6 mt-6 transition-opacity duration-500">
                    <a
                      href={product.visitWebLink || "#"}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="py-3 md:py-2 text-xs md:text-base text-white rounded-md shadow-md transition-all w-32 md:w-36 text-center"
                      style={{
                        backgroundColor: product.bgColor,
                      }}
                    >
                      Visit Website
                    </a>
                    {product.playStoreLink && (
                      <a
                        href={product.playStoreLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-white rounded-lg shadow-md transition-all"
                      >
                        <img
                          src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Google_Play_Store_badge_EN.svg/512px-Google_Play_Store_badge_EN.svg.png"
                          alt="Google Play"
                          className="w-32 md:w-36"
                        />
                      </a>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>

      {/* Arrow Buttons for small screens (placed below the product cards) */}
      <div className="flex md:hidden justify-center items-center mt-8 gap-10">
        <button
          onClick={prevSlide}
          className="p-1 rounded-full shadow-md border bg-white hover:bg-gray-100 text-black"
        >
          <ChevronLeft />
        </button>
        <button
          onClick={nextSlide}
          className="p-1 rounded-full shadow-md border bg-white hover:bg-gray-100 text-black"
        >
          <ChevronRight />
        </button>
      </div>
    </div>
  );
};

export default Products;
