import React from "react";
import img1 from "../../assets/ContactUsPage/Contactusimg4.svg";
import img2 from "../../assets/ContactUsPage/Contactusimg3.svg";
import img3 from "../../assets/ContactUsPage/Contactusimg2.svg";
import img4 from "../../assets/ContactUsPage/Contactusimg1.svg";

export default function ContactSection() {
  return (
    <div className="flex flex-col items-center justify-center py-16 px-10 lg:px-20">
      <div className="flex flex-col md:flex-row items-center justify-center space-y-10 md:space-y-0 md:space-x-20 lg:space-x-40">
        {/* Left: Contact Icons with Links */}
        <div className="grid grid-cols-2 gap-16 md:gap-24 lg:gap-28 text-center">
          <a href="mailto:info@pandoconsultants.com" target="_blank" rel="noopener noreferrer">
            <img
              src={img1}
              alt="Gmail"
              className="w-10 h-10 lg:w-14 lg:h-14 mx-auto transition-all duration-300 hover:scale-125"
            />
            <p className="text-sm mt-2">Email</p>
          </a>
          <a
            href="https://www.facebook.com/people/Pando-India-Software-Consultants/100089263113195/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={img2}
              alt="Facebook"
              className="w-10 h-10 lg:w-14 lg:h-14 mx-auto transition-all duration-300 hover:scale-125"
            />
            <p className="text-sm mt-2">Facebook</p>
          </a>
          <a
            href="https://www.linkedin.com/company/pando-india-software-consultants/mycompany/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={img3}
              alt="LinkedIn"
              className="w-10 h-10 lg:w-14 lg:h-14 mx-auto transition-all duration-300 hover:scale-125"
            />
            <p className="text-sm mt-2">LinkedIn</p>
          </a>
          <a href="tel: +91-8178745624">
            <img
              src={img4}
              alt="Phone"
              className="w-10 h-10 lg:w-14 lg:h-14 mx-auto transition-all duration-300 hover:scale-125"
            />
            <p className="text-sm mt-2">Phone</p>
          </a>
        </div>

        {/* Right: Embedded Map */}
        <div className="flex justify-center">
          <iframe
            title="Google Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.78201908367!2d77.36752357610891!3d28.606315485269707!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce59ed188b333%3A0x13827186206f997e!2sPando%20india%20software%20Consultants!5e0!3m2!1sen!2sin!4v1687154767322!5m2!1sen!2sin"
            className="w-[250px] md:w-[350px] md:h-[250px] lg:w-[450px] lg:h-[250px]"
            style={{ borderRadius: "15px", border: "1px solid white" }}
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </div>
  );
}
