/* eslint-disable react/no-unescaped-entities */
import React from "react";

const WhoWeAre = () => {
  return (
    <div className="w-full py-20 lg:py-36 px-10 lg:px-20 flex items-center justify-center">
      <div className="max-w-3xl text-center">
        <h2 className="text-xl md:text-3xl lg:text-4xl font-semibold text-[#253E52]">
          Who We Are?
        </h2>
        <p className="text-gray-600 text-xs md:text-sm lg:text-base text-justify leading-relaxed mt-8 lg:mt-14">
          Pando India Software Consultants is one of the world’s leading software consulting
          companies. Established on December 28, 2017, we aim to provide clients with a deeper
          understanding of people and inspire growth.
        </p>
        <p className="text-gray-600 text-xs md:text-sm lg:text-base leading-relaxed mt-8 text-justify">
          Pando’s service-driven approach helps "streamline the core" by leveraging digital
          technologies across legacy systems within an enterprise, enabling organizations to stay
          ahead in an ever-changing world.
        </p>
        <p className="text-gray-600 text-xs md:text-sm lg:text-base leading-relaxed mt-8 text-justify">
          We connect businesses and consumers, continuously delivering insights on demand to
          organizations of all sizes. Our solutions empower customers with the ability to shape the
          products they use, ensuring a seamless and personalized experience.
        </p>
      </div>
    </div>
  );
};

export default WhoWeAre;
