import React from "react";
import img1 from "../../assets/ContactUsPage/ContactHero.svg";

export default function ContactUsHero() {
  return (
    <div className="relative w-full h-[300px] md:h-[400px] flex items-center justify-center text-white">
      {/* Background Image */}
      <div className="absolute inset-0">
        <img src={img1} alt="About Us" className="w-full h-full object-cover" />
        {/* Dark Overlay */}
        <div className="absolute inset-0 bg-black opacity-60"></div>
      </div>

      {/* Centered Content */}
      <div className="relative z-10 text-center px-4">
        <h2 className="text-2xl md:text-3xl lg:text-4xl font-medium">Contact Us</h2>
        <p className="mt-6 md:mt-4 text-xs md:text-sm lg:text-base max-w-2xl">
          Whether you have questions about our services, need assistance, or want to explore
          partnership opportunities.
        </p>
      </div>
    </div>
  );
}
