import React from "react";
import { Outlet } from "react-router-dom";
// import { Footer } from "../../components/Footer/footers";
import { Header } from "../../components/Header";
import { Footer } from "../../components/FooterPage/Footer";

export const Layout = () => {
  return (
    <div>
      <header>
        <Header />
      </header>
      <main className="h-full">
        <Outlet />
      </main>
      <footer className="mb-0">
        <Footer />
      </footer>
    </div>
  );
};
