import React, { useEffect } from "react";
import img from "../../assets/Career/JobsHero2.svg";

const CareerHero = () => {
  useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

   return (
     <div className="bg-gray-100 py-16 flex flex-col lg:flex-row items-center justify-between">
       <div className="text-center lg:text-left px-10 lg:pl-20">
         <h2 className="text-xl md:text-2xl xl:text-[45px] font-medium  leading-snug text-[#253E52]">
           Join us and shape the future
         </h2>
         <h2 className="text-xl md:text-2xl xl:text-[45px] font-medium  leading-snug text-[#253E52] mt-2 md:mt-1 xl:mt-6">
           of technology together
         </h2>
         <p className="mt-4 lg:mt-6 text-[#787878] max-w-lg text-xs md:text-sm xl:text-base">
           Join Pando India to innovate, collaborate, and make an impact in tech. Shape the future
           with creativity and excellence!
         </p>
         <button className="mt-6 lg:mt-8 text-sm md:text-base bg-orange-500 text-white px-6 py-3 rounded-lg shadow-md hover:bg-orange-600">
           Join Us
         </button>
       </div>
       <div className="mt-8 lg:mt-0 lg:ml-8 relative w-full md:w-[50vw] max-w-xl place-items-end">
         <img src={img} alt="Team celebrating" className="w-full h-auto rounded-xs shadow-lg" />
       </div>
     </div>
   );
};

export default CareerHero;
