import { useState, useRef, useEffect } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import jobData from "./JobOpenings.json";

export default function TrendingOpportunities() {
  const [jobs, setJobs] = useState([]);
  const [activeButton, setActiveButton] = useState("next");
  const swiperRef = useRef(null);
  const [selectedJob, setSelectedJob] = useState(null);

  useEffect(() => {
    setJobs(jobData);
  }, []);

  const nextSlide = () => {
    setActiveButton("next");
    if (swiperRef.current) swiperRef.current.slideNext();
  };

  const prevSlide = () => {
    setActiveButton("prev");
    if (swiperRef.current) swiperRef.current.slidePrev();
  };

  return (
    <div className="relative py-16 px-10 lg:px-20 bg-[#F8F8FF]">
      <div className="max-w-2xl mx-auto text-center">
        <h2 className="text-xl md:text-2xl lg:text-4xl font-medium text-[#253E52] tracking-wide leading-snug">
          Latest Openings
        </h2>
        <p className="text-[#787878] mt-10 text-sm lg:text-base">
          Challenges are the core of life and with us, we assure you to have the same competitive
          and healthy work environment.
        </p>
      </div>

      {/* Card Container with Navigation Buttons */}
      <div className="relative flex items-center justify-center mt-10">
        {/* Left Arrow */}
        <button
          onClick={prevSlide}
          className={`absolute left-[-10px] lg:left-[-20px] top-1/2 transform -translate-y-1/2 p-3 rounded-full shadow-md border z-10 ${
            activeButton === "prev"
              ? "bg-black text-white"
              : "bg-white hover:bg-gray-100 text-black"
          }`}
        >
          <ChevronLeft />
        </button>

        {/* Swiper Section */}
        <div className="relative w-full">
          <Swiper
            modules={[Navigation, Autoplay]}
            spaceBetween={30}
            speed={1000}
            grabCursor={true}
            slidesPerView={1}
            onSwiper={(swiper) => (swiperRef.current = swiper)}
            breakpoints={{
              768: { slidesPerView: 2 },
              1024: { slidesPerView: 2 },
              1280: { slidesPerView: 3 },
            }}
            className="flex flex-wrap justify-center gap-6 lg:gap-12 lg:mt-14 h-full p-4"
          >
            {jobs.map((job, index) => (
              <SwiperSlide key={index}>
                <div className="relative flex flex-col items-center h-full">
                  <div className="w-full md:w-[40vw] xl:w-[23vw] h-full shadow-lg p-8 bg-white rounded-lg flex flex-col justify-between">
                    <img
                      src={require(`../../assets${job.image}`)}
                      alt={job.title}
                      className="w-full h-28 lg:h-40 object-cover rounded-lg"
                    />
                    <div className="mt-4 flex-grow flex flex-col">
                      <h3 className="text-base lg:text-xl font-medium text-gray-900 py-2">
                        {job.title}
                      </h3>
                      <div className="text-gray-600 mt-2 mb-4 text-xs md:text-sm text-justify flex-grow overflow-hidden h-40">
                        {job.description}
                      </div>
                    </div>
                    <button
                      onClick={() => setSelectedJob(job)}
                      className="text-[#EE8528] text-lg text-left font-medium hover:text-orange-600"
                    >
                      view more
                    </button>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        {/* Right Arrow */}
        <button
          onClick={nextSlide}
          className={`absolute right-[-10px] lg:right-[-20px] top-1/2 transform -translate-y-1/2 p-3 rounded-full shadow-md border z-10 ${
            activeButton === "next"
              ? "bg-black text-white"
              : "bg-white hover:bg-gray-100 text-black"
          }`}
        >
          <ChevronRight />
        </button>
      </div>

      {/* Modal Popup */}
      {selectedJob && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 p-4 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-lg w-full relative">
            <h3 className="text-xl font-semibold text-gray-900">{selectedJob.title}</h3>
            <p className="text-gray-600 mt-2">Available Positions: {selectedJob.positions}</p>
            <p className="text-gray-700 mt-4">{selectedJob.description}</p>
            <p className="text-gray-700 mt-8">Write Us</p>
            <a
              href="mailto:career@pandoconsultants.com"
              className="text-blue-500 underline mt-2 block"
            >
              career@pandoconsultants.com
            </a>
            <button
              onClick={() => setSelectedJob(null)}
              className="mt-4 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
